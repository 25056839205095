import React from "react";
import Modal from "react-modal";
import "./CustomModal.css";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="modal-header">
        <h2 className="modal-title">{title}</h2>
      </div>
      <div className="modal-body">{children}</div>
    </Modal>
  );
};

export default CustomModal;
