import React, { useEffect, useState } from "react";
import "./App.css";
import Ankieta from "./components/Ankieta/Ankieta";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import LoadingScreen from "./components/Ankieta/utils/LoadingScreen";

const App: React.FC = () => {
  const [isValidUser, setIsValidUser] = useState<boolean | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const user: string | null = urlParams.get("user");
  const orderId: string | null = urlParams.get("order_id");
  const productId: string | null = urlParams.get("product_id");

  useEffect(() => {
    if (!user) {
      setError("Brak wymaganego parametru użytkownika.");
      setIsValidUser(false);
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch(`verify_user.php?user=${user}`);
        if (!response.ok) {
          throw new Error(`Błąd sieci: ${response.statusText}`);
        }
        const data = await response.json();
        if (data.form_submitted) {
          setIsFormSubmitted(data.form_submitted);
        }
        if (data.valid) {
          setUserData(data.data);
          setIsValidUser(true);
        } else {
          setError(
            data.message || "Wystąpił błąd: Nieprawidłowe dane użytkownika."
          );
          setIsValidUser(false);
        }
      } catch (error) {
        setError("Wystąpił błąd podczas ładowania danych.");
        setIsValidUser(false);
      }
    };

    fetchUserData();
  }, [user]);

  if (isValidUser === null) {
    return (
      <div className="App">
        <div className="page-container">
          <Header />
          <div className="page-content">
            <LoadingScreen loading={isValidUser ?? false} />;
            <Footer />
          </div>
        </div>
      </div>
    );
  }

  if (!isValidUser) {
    return (
      <div className="App">
        <div className="page-container">
          <Header />
          <div className="page-content">
            <div id="error-message" className="error-message">
              {error}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="page-container">
        <Header />
        <div className="page-content">
          {userData && (
            <Ankieta user={user} orderId={orderId} productId={productId} />
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
